import React, { Component } from "react";

class Plus extends Component {
  render() {
    return (
      <svg role="img" className={this.props.className} viewBox="0 0 576 512">
        <path
          fill="currentColor"
          d="M553.5 231.8c8.7 14.9 8.7 33.4 0 48.4l-112 192c-8.6 14.7-24.4 23.8-41.5 23.8H176c-17.1 0-32.9-9.1-41.5-23.8l-112-192c-8.7-14.9-8.7-33.4 0-48.4l112-192C143.1 25.1 158.9 16 176 16h224c17.1 0 32.9 9.1 41.5 23.8l112 192zM432 228c0-6.6-5.4-12-12-12h-92v-92c0-6.6-5.4-12-12-12h-56c-6.6 0-12 5.4-12 12v92h-92c-6.6 0-12 5.4-12 12v56c0 6.6 5.4 12 12 12h92v92c0 6.6 5.4 12 12 12h56c6.6 0 12-5.4 12-12v-92h92c6.6 0 12-5.4 12-12v-56z"
        />
      </svg>
    );
  }
}

export default Plus;
