import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import * as actions from '../../redux/actions';
import { connect } from 'react-redux';
import { Oval as Loading } from '../loaders';

import CardSectionUpdate from './card_section_update';

class InjectedUpdateForm extends Component {
  componentWillMount() {
    this.props.resetError();
    this.props.clearSuccess();
  }

  handleSubmit = event => {
    event.preventDefault();
    const email = localStorage.getItem('email');
    this.props.stripe.createToken({ email: email }).then(({ token }) => {
      const id = token.id;
      this.props.stripeUpdate({ email, id });
    });
  };

  renderFail() {
    if (this.props.stripeUpdateFail) {
      return (
        <div className="alert alert-danger" style={{ marginTop: 20 }}>
          <strong>{this.props.stripeUpdateFail}</strong>
        </div>
      );
    }
  }

  renderContent() {
    if (this.props.loading) {
      return (
        <div className="text-center">
          <Loading className="oval-loader" />
        </div>
      );
    } else {
      return (
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <form onSubmit={this.handleSubmit.bind(this)} className="my-form">
              <CardSectionUpdate />
              <button className="btn btn-gymnerd">Update</button>
              {this.renderFail()}
            </form>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="container">
        <section style={{ paddingTop: 40 }}>
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12">
              <p style={Styles.section}>Update Your Card</p>
            </div>
          </div>
        </section>

        <section>{this.renderContent()}</section>
      </div>
    );
  }
}

const Styles = {
  section: {
    fontFamily: 'black_ops_oneregular',
    fontSize: 30,
    fontWeight: 'bold'
  }
};

function mapStateToProps(state) {
  return {
    stripeUpdateSuccess: state.auth.success,
    stripeUpdateFail: state.auth.error
  };
}

export default connect(
  mapStateToProps,
  actions
)(injectStripe(InjectedUpdateForm));
