import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { Switch, Route, Router } from 'react-router-dom';
import reduxThunk from 'redux-thunk';
import reducers from './redux/reducers';
import history from './config/history';

import App from './App';
import Nav from './components/common/Nav';
import Footer from './components/common/Footer';
import Dashboard from './components/common/Dashboard';
import Signin from './components/auth/Signin';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import UpdateEmail from './components/auth/UpdateEmail';
import UpdatePassword from './components/auth/UpdatePassword';
import UpdateCard from './components/stripe/update_card';
import Cancel from './components/stripe/Cancel';
import Contact from './components/common/Contact';
import Privacy from './components/common/Privacy';

import Auth from './components/auth/RequireAuth';
import * as serviceWorker from './serviceWorker';

import { AUTH_USER } from './redux/types';

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(reducers);

const token = localStorage.getItem('token');
if (token) {
  store.dispatch({ type: AUTH_USER });
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <div>
        <Nav />
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/profile" component={Auth(Dashboard)} />
          <Route exact path="/emailupdate" component={Auth(UpdateEmail)} />
          <Route
            exact
            path="/passwordupdate"
            component={Auth(UpdatePassword)}
          />
          <Route exact path="/cardupdate" component={Auth(UpdateCard)} />
          <Route exact path="/cancel" component={Auth(Cancel)} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
        <Footer />
      </div>
    </Router>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
