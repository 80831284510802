import React from 'react';
import styled from 'styled-components';

const Gradient = styled.div`
  background: #00b1dc;
  content: '';
  overflow: visible;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 420px;
  display: flex;
  transform: skewY(-6deg);
  transform-origin: top left;
`;

const ImageHolder = styled.div`
  text-align: center;
  transform: skewY(6deg);
  padding-top: 75px;
`;

const TextHolder = styled.div`
  margin-left: auto;
  margin-right: auto;
  transform: skewY(6deg);
  margin-bottom: 30px;
`;

const MyHeader = props => {
  return (
    <header>
      <Gradient style={{ zIndex: 0 }}>
        <div className="container">
          <ImageHolder>
            <img
              src="https://d22448hko77q9b.cloudfront.net/logoBW.png"
              height="200"
              alt="Gym Nerd"
            />
          </ImageHolder>
          <TextHolder>
            <p className="text-center" style={Styles.header}>
              {props.title}
            </p>
          </TextHolder>
        </div>
      </Gradient>
    </header>
  );
};

const Styles = {
  header: {
    fontFamily: 'black_ops_oneregular',
    fontSize: 30,
    fontWeight: 'bold',
    color: 'black',
    marginBottom: 20
  }
};

export default MyHeader;
