import React, { Component } from 'react';
import Header from '../Header';

class Privacy extends Component {
  render() {
    return (
      <div>
        <Header title="Privacy Policy" />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-1" />
            <div className="col-sm-10">
              <h1
                className="text-center"
                style={{ fontFamily: 'Black Ops One', fontSize: 30 }}
              >
                TERMS AND CONDITIONS FOR USING GYM NERD
              </h1>
              <p>
                Please carefully read the following Terms of Use (collectively
                with Gym Nerd's Privacy Policy, the "Terms of Use") fully before
                using Gym Nerd's mobile applications (the "Applications"),
                www.gymnerd.app (the "Site"), and the services, features,
                content, goods or applications offered by Kickbush Holdings,
                LLC. ("Gym Nerd", "Gym Nerd with", "we", "us" or "our")
                (together with the Applications and the Site, the "Services").
                These Terms of Use set forth the legally binding terms and
                conditions for your use of the Applications, Site and the
                Services.
              </p>
              <p>
                1. Acceptance By using the Services in any manner, including
                downloading the Applications or visiting or browsing the Site,
                you agree to these Terms of Use and all other operating rules,
                policies and procedures that may be published from time to time
                on the Site or the Applications, each of which is incorporated
                herein by reference and which may be updated from time to time
                without notice to you. These Terms of Use apply to all users of
                the Services, including, without limitation, users who are
                contributors of content, information, and other materials or
                services, registered or otherwise.
              </p>
              <p>
                Services may be subject to additional terms and conditions
                specified by us from time to time; your use of such Services is
                subject to those additional terms and conditions, which are
                incorporated into these Terms of Use by this reference.
              </p>
              <p>
                ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN
                TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION SECTION BELOW,
                YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY
                BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO
                PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE
                ARBITRATION.ARBITRATION. YOU AGREE THAT ALL DISPUTES BETWEEN YOU
                AND US (WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD PARTY) WITH
                REGARD TO YOUR RELATIONSHIP WITH US, INCLUDING DISPUTES RELATED
                TO THESE TERMS OF USE, YOUR USE OF THE SERVICES, AND/OR RIGHTS
                OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY BINDING,
                INDIVIDUAL ARBITRATION UNDER THE AMERICAN ARBITRATION
                ASSOCIATION'S RULES FOR ARBITRATION OF CONSUMER-RELATED DISPUTES
                AND YOU AND WE HEREBY EXPRESSLY WAIVE TRIAL BY JURY. DISCOVERY
                AND RIGHTS TO APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED
                THAN IN A LAWSUIT, AND OTHER RIGHTS THAT YOU AND WE WOULD HAVE
                IN COURT MAY NOT BE AVAILABLE IN ARBITRATION. As an alternative,
                you may bring your claim in your local "small claims" court, if
                permitted by that small claims court's rules and if within such
                court's jurisdiction, unless such action is transferred, removed
                or appealed to a different court. You may bring claims only on
                your own behalf. Neither you nor we will participate in a class
                action or class-wide arbitration for any claims covered by this
                agreement to arbitrate. YOU ARE GIVING UP YOUR RIGHT TO
                PARTICIPATE AS A CLASS REPRESENTATIVE OR CLASS MEMBER ON ANY
                CLASS CLAIM YOU MAY HAVE AGAINST US INCLUDING ANY RIGHT TO CLASS
                ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL ARBITRATIONS. You
                also agree not to participate in claims brought in a private
                attorney general or representative capacity, or consolidated
                claims involving another person's account, if we are a party to
                the proceeding. This dispute resolution provision will be
                governed by the Federal Arbitration Act and not by any state law
                concerning arbitration. In the event the American Arbitration
                Association is unwilling or unable to set a hearing date within
                one hundred and sixty (160) days of filing the case, then either
                we or you can elect to have the arbitration administered instead
                by the Judicial Arbitration and Mediation Services. Judgment on
                the award rendered by the arbitrator may be entered in any court
                having competent jurisdiction. Any provision of applicable law
                notwithstanding, the arbitrator will not have authority to award
                damages, remedies or awards that conflict with these Terms of
                Use. If the prohibition against class actions and other claims
                brought on behalf of third parties contained above is found to
                be unenforceable, then all of the preceding language in this
                Arbitration section will be null and void. This arbitration
                agreement will survive the termination of your relationship with
                us.
              </p>
              <p>
                2. Suitability You represent and warrant that you are at least
                14 years of age. If you are under age 14, you may not, under any
                circumstances or for any reason, use the Services. We may, in
                our sole discretion, refuse to offer the Services to any person
                or entity and change its eligibility criteria at any time. These
                Terms of Use are void where prohibited by law. You are solely
                responsible for ensuring that these Terms of Use are in
                compliance with all laws, rules and regulations applicable to
                you and the right to access the Services is revoked where these
                Terms of Use or use of the Services is prohibited or to the
                extent offering, sale or provision of the Services conflicts
                with any applicable law, rule or regulation. Further, the
                Services are offered only for your use, and not for the use or
                benefit of any third party. NOTICE TO PARENTS AND GUARDIANS:
                Users are responsible for monitoring and supervising their
                child's use of the Services. If your child is using the Services
                and is under age 14, please contact us immediately so that we
                can disable his or her access. If you have questions about the
                Services, please contact us at www.gymnerd.app.
              </p>
              <p>
                3. Registration To sign up for the Services, you must register
                for an account on the Services (an "Account"). You must provide
                accurate and complete information and keep your Account
                information updated. Failure to do so shall constitute a breach
                of these Terms of Use. A breach of these Terms of Use may result
                in immediate termination of your account. You shall not: (i)
                select or use as a username a name of another person with the
                intent to impersonate that person; (ii) use as a username a name
                subject to any rights of a person other than you without
                appropriate authorization; (iii) use, as a username, a name that
                is otherwise offensive, vulgar or obscene. You are solely
                responsible for the activity that occurs on your Account, and
                for keeping your Account password secure. You may never use
                another person's user account or registration information for
                the Services without permission. You must notify us immediately
                of any change in your eligibility to use the Services (including
                any changes to or revocation of any licenses from state
                authorities), breach of security or unauthorized use of your
                Account. You should never publish, distribute or post login
                information for your Account. Gym Nerd reserves the right to
                refuse registration of, or cancel an Account in Gym Nerd's
                discretion. You shall have the ability to delete your Account
                through a request made to us. By accessing the Services, you
                permit us to access certain information from your profile on
                Facebook or any other such third party services for use by the
                Services. By using the Services, you are authorizing us to
                collect, store, retain, and use indefinitely, in accordance with
                our Privacy Policy, any and all information that you permitted
                Facebook or any other such third party services to provide to
                us.
              </p>
              <p>
                4. Use of the Service The Services provide users with guidance
                and information on how to improve their fitness, health and
                nutrition, but THE SERVICES ARE NOT A MEDICAL ORGANIZATION OR
                MEDICAL DOCTORS. YOU SHOULD NOT RELY ON THIS GUIDANCE AND
                INFORMATION AS PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR
                TREATMENT. BEFORE BEGINNING THE SERVICES, YOU SHOULD CONSULT A
                PHYSICIAN. Our recommended workout plans and exercises, even if
                they are tailored to individual users, should not be
                misconstrued as medical advice, diagnoses or treatment. IF YOU
                HAVE ANY CONCERNS OR QUESTIONS ABOUT YOUR HEALTH, YOU SHOULD
                ALWAYS CONSULT WITH A PHYSICIAN OR OTHER HEALTHCARE
                PROFESSIONAL. THE USE OF ANY INFORMATION OR PROGRAMS PROVIDED ON
                THE SERVICES IS SOLELY AT YOUR OWN RISK. The Services are
                intended for use only by individuals healthy enough to perform
                strenuous exercise. In becoming a user of the Services, you
                affirm that a physician has specifically approved your use of
                the Services, OR that all of the following statements are true:
                no physician has ever informed you that you have a heart
                condition or that you should only do physical activities
                recommended by a physician; you have never felt chest pain when
                engaging in physical activity; you have not experienced chest
                pain when not engaged in physical activity at any time within
                the past month; you have never lost your balance because of
                dizziness and you have never lost consciousness; you do not have
                a bone or joint problem that could be made worse by a change in
                your physical activity; your physician is not currently
                prescribing drugs for your blood pressure or heart condition;
                you do not have a history of high blood pressure, and no one in
                your immediate family has a history of high blood pressure or
                heart problems; you do not have a condition of high-cholesterol,
                diabetes, obesity or arthritis; and you do not know of any other
                reason you should not exercise. If applicable, you further
                affirm that (A) you are not pregnant, breastfeeding or
                lactating, or (B) your physician has specifically approved your
                use of the Services. You should discontinue exercise in cases
                where it causes pain or severe discomfort, and should consult a
                medical expert prior to returning to exercise in such cases. We
                reserve the right to deny you access to the Services for any
                reason or no reason. You should be aware that there are inherent
                physical and mental health risks to exercise, including risk of
                injury or illness. By accessing the Services, you acknowledge
                and agree that your performance of any and all exercises or
                activities recommended by the Services is wholly at your own
                risk. Neither Gym Nerd nor Kickbush Holdings, LLC will be liable
                for any physical or mental injury or illness that may result,
                whether directly or indirectly, from any of our recommended
                workout plans or exercises. While Gym Nerd may provide
                guidelines such as written descriptions, pictures, or videos
                describing how to perform specific exercises or activities, you
                assume sole responsibility for performing those exercises or
                activities with proper form, as risk of injury or illness
                increases with improper form. We encourage you to seek multiple
                sources of information regarding how to perform each exercise
                correctly and to consider consulting with a qualified coach,
                instructor, personal trainer, or physical therapist, especially
                if you are new to any of the forms of training or activity you
                seek to perform.
              </p>
              <p>
                5. Content Definition: For purposes of these Terms of Use, the
                term "Content" includes, without limitation, information, data,
                text, photographs, videos, audio clips, written posts and
                comments, software, scripts, graphics, and interactive features
                generated, provided, or otherwise made accessible on or through
                the Services. For the purposes of this Agreement, "Content" also
                includes all User Content (as defined below). User Content: All
                Content added, created, uploaded, submitted, distributed, or
                posted to the Services by users (collectively "User Content"),
                whether publicly posted or privately transmitted, is the sole
                responsibility of the person who originated such User Content.
                You represent that all User Content provided by you is accurate,
                complete, up-to-date, and in compliance with all applicable
                laws, rules and regulations. You acknowledge that all Content,
                including User Content, accessed by you using the Services is at
                your own risk and you will be solely responsible for any damage
                or loss to you or any other party resulting therefrom. We do not
                guarantee that any Content you access on or through the Services
                is or will continue to be accurate. Notices and Restrictions:
                The Services may contain Content specifically provided by us,
                our partners or our users and such Content is protected by
                copyrights, trademarks, service marks, patents, trade secrets or
                other proprietary rights and laws. You shall abide by and
                maintain all copyright notices, information, and restrictions
                contained in any Content accessed through the Services. Use
                License: Subject to these Terms of Use, we grant each user of
                the Services a worldwide, non-exclusive, non-sub-licensable and
                non-transferable personal license to use (i.e., to download and
                display locally) Content solely for purposes of using the
                Services. Use, reproduction, modification, distribution or
                storage of any Content for other than purposes of using the
                Services is expressly prohibited without prior written
                permission from us. You shall not sell, license, rent, or
                otherwise use or exploit any Content for commercial use or in
                any way that violates any third party right. As between you and
                Gym Nerd, Gym Nerd shall own all title, ownership rights, and
                intellectual property rights in and to the Services, and any
                copies or portions thereof. License Grant: By submitting User
                Content through the Services, you hereby do and shall grant us a
                worldwide, non-exclusive, perpetual, royalty-free, fully paid,
                sub-licensable and transferable license to use, edit, modify,
                truncate, aggregate, reproduce, distribute, prepare derivative
                works of, display, perform, and otherwise fully exploit the User
                Content in connection with the Applications, the Site, the
                Services and our (and our successors' and assigns') businesses,
                including without limitation for promoting and redistributing
                part or all of the Applications, the Site or the Services (and
                derivative works thereof) in any media formats and through any
                media channels (including, without limitation, third party
                websites and feeds), and including after your termination of
                your Account or the Services. You also hereby do and shall grant
                each user of the Applications, Site and/or the Services who has
                access to your User Content a non-exclusive, perpetual license
                to use, edit, modify, reproduce, distribute, prepare derivative
                works of, display and perform such User Content, including after
                your termination of your Account or the Services. For clarity,
                the foregoing license grants to us and our users does not affect
                your other ownership or license rights in your User Content,
                including the right to grant additional licenses to your User
                Content, unless otherwise agreed in writing. You represent and
                warrant that you have all rights to grant such licenses to us
                without infringement or violation of any third party rights,
                including without limitation, any privacy rights, publicity
                rights, copyrights, trademarks, contract rights, or any other
                intellectual property or proprietary rights. Availability of
                Content: We do not guarantee that any Content will be made
                available on the Applications or the Site or through the
                Services. We reserve the right to, but do not have any
                obligation to, (i) remove, edit or modify any Content in our
                sole discretion, at any time, without notice to you and for any
                reason (including, but not limited to, upon receipt of claims or
                allegations from third parties or authorities relating to such
                Content or if we are concerned that you may have violated these
                Terms of Use), or for no reason at all and (ii) to remove or
                block any Content from the Services.
              </p>
              <p>
                6. Rules of Conduct As a condition of use, you promise not to
                use the Services for any purpose that is prohibited by these
                Terms of Use. You shall not (and shall not permit any third
                party to) either (a) take any action or (b) upload, download,
                post, submit or otherwise distribute or facilitate distribution
                of any Content on or through the Service, including without
                limitation any User Content, that:infringes any patent,
                trademark, trade secret, copyright, right of publicity or other
                right of any other person or entity or violates any law or
                contractual duty (see our DMCA Copyright Policy, located at the
                end of these Terms of Use);you know is false, misleading,
                untruthful or inaccurate; is unlawful, threatening, abusive,
                harassing, defamatory, libelous, deceptive, fraudulent, invasive
                of another's privacy, otherwise harms a third party, tortious,
                obscene, vulgar, pornographic, offensive, profane, contains or
                depicts nudity, contains or depicts sexual activity, or is
                otherwise inappropriate as determined by us in our sole
                discretion; constitutes unauthorized or unsolicited advertising,
                junk or bulk e-mail ("spamming");contains software viruses or
                any other computer codes, files, or programs that are designed
                or intended to disrupt, damage, limit or interfere with the
                proper function of any software, hardware, or telecommunications
                equipment or to damage or obtain unauthorized access to any
                system, data, password or other information of ours or of any
                third party; impersonates any person or entity, including any of
                our employees or representatives; or includes anyone's
                identification documents or sensitive financial information. You
                shall not: (i) take any action that imposes or may impose (as
                determined by us in our sole discretion) an unreasonable or
                disproportionately large load on our (or our third party
                providers') infrastructure; (ii) interfere or attempt to
                interfere with the proper working of the Services or any
                activities conducted on the Services; (iii) bypass, circumvent
                or attempt to bypass or circumvent any measures we may use to
                prevent or restrict access to the Services (or other accounts,
                computer systems or networks connected to the Services); (iv)
                run any form of auto-responder or "spam" on the Services; (v)
                use manual or automated software, devices, or other processes to
                "crawl" or "spider" any page of the Applications or the Site;
                (vi) harvest or scrape any Content from the Services; or (vii)
                otherwise take any action in violation of our guidelines and
                policies. You shall not (directly or indirectly): (i) decipher,
                decompile, disassemble, reverse engineer or otherwise attempt to
                derive any source code or underlying ideas or algorithms of any
                part of the Services (including without limitation any
                application), except to the limited extent applicable laws
                specifically prohibit such restriction, (ii) modify, translate,
                or otherwise create derivative works of any part of the
                Services, (iii) copy, rent, lease, distribute, use the Services
                for timesharing or service bureau purposes or otherwise transfer
                any of the rights that you receive hereunder, or (iv) remove or
                obscure any proprietary notices on the Services. You shall abide
                by all applicable local, state, national and international laws
                and regulations. We also reserve the right to access, read,
                preserve, and disclose any information as we reasonably believe
                is necessary to (i) satisfy any applicable law, regulation,
                legal process or governmental request, (ii) enforce these Terms
                of Use, including investigation of potential violations hereof,
                (iii) detect, prevent, or otherwise address fraud, security or
                technical issues, (iv) respond to user support requests, or (v)
                protect the rights, property or safety of us, our users and the
                public.
              </p>
              <p>
                7. Location Based Services Where the Services collect
                location-based information, the Services may do it on an
                anonymous basis for the improvement of our Services. You hereby
                consent to the Services' use of anonymous location based
                services information collected from users. Where the
                location-based information is personally identifiable the
                Services will give you options to manage your disclosure of this
                information within the Services. Depending on the
                functionalities available on your mobile device, you may benefit
                from advanced options to manage the location-based information.
              </p>
              <p>
                8. Third Party Services The Services may permit you to link to
                other websites, services or resources on the Internet, and other
                websites, services or resources may contain links to the
                Services. The Services may also permit you to connect to third
                party hardware and equipment. When you access third party
                resources, you do so at your own risk. These other resources are
                not under our control, and you acknowledge that we are not
                responsible or liable for the content, functions, accuracy,
                legality, appropriateness or any other aspect of such websites,
                resources, hardware or equipment. The inclusion of any such link
                or connection does not imply our endorsement or any association
                between us and their providers. You further acknowledge and
                agree that we shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with the use of or reliance on any
                such content, goods or services available on or through any such
                website, resource or hardware or equipment.
              </p>
              <p>
                9. Apple Device and Application Terms. As you are accessing the
                Services via an Application on a device provided by Apple, Inc.
                ("Apple") or an Application obtained through the Apple App
                Store, the following shall apply: Both you and Gym Nerd
                acknowledge that these Terms of Use are concluded between you
                and Gym Nerd only, and not with Apple, and that Apple is not
                responsible for the Application or the Content; The Application
                is licensed to you on a limited, non-exclusive,
                non-transferrable, non-sub-licensable basis, solely to be used
                in connection with the Services for your private, personal,
                non-commercial use, subject to all the terms and conditions of
                these Terms of Use as they are applicable to the Services; You
                will only use the Application in connection with an Apple device
                that you own or control; You acknowledge and agree that Apple
                has no obligation whatsoever to furnish any maintenance and
                support services with respect to the Application; In the event
                of any failure of the Application to conform to any applicable
                warranty, including those implied by law, you may notify Apple
                of such failure; upon notification, Apple's sole warranty
                obligation to you will be to refund to you the purchase price,
                if any, of the Application; You acknowledge and agree that Gym
                Nerd, and not Apple, is responsible for addressing any claims
                you or any third party may have in relation to the Application;
                You acknowledge and agree that, in the event of any third party
                claim that the Application or your possession and use of the
                Application infringes that third party's intellectual property
                rights, Gym Nerd, and not Apple, will be responsible for the
                investigation, defense, settlement and discharge of any such
                infringement claim; You represent and warrant that you are not
                located in a country subject to a U.S. Government embargo, or
                that has been designated by the U.S. Government as a "terrorist
                supporting" country, and that you are not listed on any U.S.
                Government list of prohibited or restricted parties; Both you
                and Gym Nerd acknowledge and agree that, in your use of the
                Application, you will comply with any applicable third party
                terms of agreement which may affect or be affected by such use;
                and Both you and Gym Nerd acknowledge and agree that Apple and
                Apple's subsidiaries are third party beneficiaries of these
                Terms of Use, and that upon your acceptance of these Terms of
                Use, Apple will have the right (and will be deemed to have
                accepted the right) to enforce these Terms of Use against you as
                the third party beneficiary hereof.
              </p>
              <p>
                10. In-App Purchases Through the Services, you may purchase ("In
                App Purchase") certain goods or additional features or
                Applications designed to enhance the performance of the Services
                ("Goods"). When you purchase Goods, you are doing so through
                Apple iTunes service and you are agreeing to Apple's iTunes'
                Terms and Conditions.11. Payments and Billing Paid Services:
                Certain of our Services may be subject to payments now or in the
                future (the "Paid Services"). Please note that any payment terms
                presented to you in the process of using or signing up for a
                Paid Service are deemed part of this Agreement. Billing: We use
                a third-party payment processor (the "Payment Processor") to
                bill you through a payment account (your "Billing Account") for
                use of the Paid Services. The processing of payments will be
                subject to the terms, conditions and privacy policies of the
                Payment Processor in addition to this Agreement. We are not
                responsible for error by the Payment Processor. By choosing to
                use Paid Services, you agree to pay us, through the Payment
                Processor, all charges at the prices then in effect for any use
                of such Paid Services in accordance with the applicable payment
                terms and you authorize us, through the Payment Processor, to
                charge your chosen payment provider (your "Payment Method"). You
                agree to make payment using that selected Payment Method. We
                reserve the right to correct any errors or mistakes that it
                makes even if it has already requested or received payment.
                Payment Method: The terms of your payment will be based on your
                Payment Method and may be determined by agreements between you
                and the financial institution, credit card issuer or other
                provider of your chosen Payment Method. If we, through the
                Payment Processor, do not receive payment from you, you agree to
                pay all amounts due on your Billing Account upon demand.
                Recurring Billing: Some of the Paid Services may consist of an
                initial period, for which there is a one-time charge, followed
                by recurring period charges as agreed to by you. By choosing a
                recurring payment plan, you acknowledge that such Services have
                an initial and recurring payment feature and you accept
                responsibility for all recurring charges prior to cancellation.
                WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER
                AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT
                OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS
                AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE
                WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD
                ACT. TO TERMINATE YOU AUTHORIZATION OR CHANGE YOUR PAYMENT
                METHOD, GO TO WWW.GYMNERD.APP. Current Information Required. YOU
                MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR
                BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO
                KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH
                AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT
                CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR
                PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR
                LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF
                SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR
                USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE
                AT WWW.GYMNERD.APP. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING
                INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY
                USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE
                TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE. Change in
                Amount Authorized: If the amount to be charged to your Billing
                Account varies from the amount you preauthorized (other than due
                to the imposition or change in the amount of state sales taxes),
                you have the right to receive, and we shall provide, notice of
                the amount to be charged and the date of the charge before the
                scheduled date of the transaction. Any agreement you have with
                your payment provider will govern your use of your Payment
                Method. You agree that we may accumulate charges incurred and
                submit them as one or more aggregate charges during or at the
                end of each billing cycle. Reaffirmation of Authorization: Your
                non-termination or continued use of a Paid Service reaffirms
                that we are authorized to charge your Payment Method for that
                Paid Service. We may submit those charges for payment and you
                will be responsible for such charges. This does not waive our
                right to seek payment directly from you. Your charges may be
                payable in advance, in arrears, per usage, or as otherwise
                described when you initially selected to use the Paid Service.
                Free Trials and Other Promotions: Any free trial or other
                promotion that provides access to a Paid Service must be used
                within the specified time of the trial. You must stop using a
                Paid Service before the end of the trial period in order to
                avoid being charged for that Paid Service. If you cancel prior
                to the end of the trial period and are inadvertently charged for
                a Paid Service, please contact us our customer service at
                www.gymnerd.app.
              </p>
              <p>
                12. Termination We may terminate your access to all or any part
                of the Services at any time, with or without cause, with or
                without notice, effective immediately, which may result in the
                forfeiture and destruction of all information associated with
                your membership. Upon termination, you agree that you shall no
                longer access (or attempt to access) the Services. If you wish
                to terminate your Account, you may do so by contacting us at
                www.app.gymnerd.app Any fees paid hereunder are non-refundable.
                All provisions of these Terms of Use, which by their nature
                should survive termination shall survive termination, including,
                without limitation, licenses of User Content, ownership
                provisions, warranty disclaimers, indemnity and limitations of
                liability.13. Warranty Disclaimer We have no special
                relationship with or fiduciary duty to you. You release us from
                all liability for you having acquired or not acquired Content
                through the Services. We make no representations concerning any
                Content contained in or accessed through the Services, and we
                will not be responsible or liable for the accuracy, copyright
                compliance, or legality of material or Content contained in or
                accessed through the Services. THE SERVICES AND CONTENT ARE
                PROVIDED "AS IS", "AS AVAILABLE" AND WITHOUT WARRANTY OF ANY
                KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
                IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY
                AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED
                BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE
                EXPRESSLY DISCLAIMED. WE, AND OUR DIRECTORS, EMPLOYEES, AGENTS,
                SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT WARRANT THAT:
                (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR
                TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED;
                (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE
                SERVICES IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV)
                THE RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS.
                YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK. THE
                FOREGOING DISCLAIMER SHALL NOT APPLY TO THE EXTENT PROHIBITED BY
                APPLICABLE LAW.
              </p>
              <p>
                14. Indemnification You shall defend, indemnify, and hold
                harmless us, our affiliates and each of our and their respective
                employees including without limitation our trainers, Steve Cook,
                Jacob Hutton, contractors, directors, suppliers and
                representatives from all liabilities, claims, and expenses,
                including reasonable attorneys' fees, that arise from or relate
                to your use or misuse of, or access to, the Services, Content,
                or otherwise from your User Content, violation of these Terms of
                Use, or infringement by you, or any third party using your
                Account or identity in the Services, of any intellectual
                property or other right of any person or entity. We reserve the
                right to assume the exclusive defense and control of any matter
                otherwise subject to indemnification by you, in which event you
                will assist and cooperate with us in asserting any available
                defenses.
              </p>
              <p>
                15. Limitation of Liability IN NO EVENT SHALL GYM NERD NOR
                KICKBUSH HOLDINGS, LLC, BE LIABLE UNDER CONTRACT, TORT, STRICT
                LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY
                WITH RESPECT TO THE SERVICES (1) FOR ANY LOST PROFITS, DATA
                LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR
                SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR
                CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, EVEN IF
                FORESEEABLE, SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (2)
                FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF
                THE SOURCE OF ORIGINATION), OR (3) FOR ANY DIRECT DAMAGES IN
                EXCESS OF (IN THE AGGREGATE) $100.00 (U.S.). THE FOREGOING
                LIMITATIONS SHALL NOT APPLY TO THE EXTENT PROHIBITED BY
                APPLICABLE LAW.
              </p>
              <p>
                16. Governing Law and Jurisdiction These Terms of Use shall be
                governed by and construed in accordance with the laws of the
                State of Utah, including its conflicts of law rules, and the
                United States of America. You agree that any dispute arising
                from or relating to the subject matter of these Terms of Use
                shall be governed by the exclusive jurisdiction and venue of the
                state and Federal courts of Salt Lake City, Utah.
              </p>
              <p>
                17. Modification We reserve the right, in our sole discretion,
                to modify or replace any of these Terms of Use, or change,
                suspend, or discontinue the Services (including without
                limitation, the availability of any feature, database, or
                content) at any time. We may also impose limits on certain
                features and services or restrict your access to parts or all of
                the Services without notice or liability. It is your
                responsibility to check these Terms of Use periodically for
                changes. Your continued use of the Services following
                notification of any changes to these Terms of Use constitutes
                acceptance of those changes.
              </p>
              <p>
                18. Miscellaneous Entire Agreement and Severability: These Terms
                of Use are the entire agreement between you and us with respect
                to the Services, including use of the Applications and the Site,
                and supersede all prior or contemporaneous communications and
                proposals (whether oral, written or electronic) between you and
                us with respect to the Services, and all modifications to these
                Terms of Use must be in writing and signed by both parties,
                except as otherwise provided herein. If any provision of these
                Terms of Use is found to be unenforceable or invalid, that
                provision will be limited or eliminated to the minimum extent
                necessary so that these Terms of Use will otherwise remain in
                full force and effect and enforceable. The failure of either
                party to exercise in any respect any right provided for herein
                shall not be deemed a waiver of any further rights here under
                Force Majeure: We shall not be liable for any failure to perform
                our obligations hereunder where such failure results from any
                cause beyond our reasonable control, including, without
                limitation, mechanical, electronic or communications failure or
                degradation (including "line-noise" interference).Assignment:
                These Terms of Use are personal to you, and are not assignable,
                transferable or sub-licensable by you except with our prior
                written consent. We may assign, transfer or delegate any of our
                rights and obligations hereunder without consent. Agency: No
                agency, partnership, joint venture, or employment relationship
                is created as a result of these Terms of Use and neither party
                has any authority of any kind to bind the other in any respect.
                Notices: Unless otherwise specified in these Term of Service,
                all notices under these Terms of Use will be in writing and will
                be deemed to have been duly given when received, if personally
                delivered or sent by certified or registered mail, return
                receipt requested; when receipt is electronically confirmed, if
                transmitted by facsimile or e-mail; or the day after it is sent,
                if sent for next day delivery by recognized overnight delivery
                service. Electronic notices should be sent to
                support@gymnerd.app. No Waiver: Our failure to enforce any part
                of these Terms of Use shall not constitute a waiver of our right
                to later enforce that or any other part of these Terms of Use.
                Waiver of compliance in any particular instance does not mean
                that we will waive compliance in the future. In order for any
                waiver of compliance with these Terms of Use to be binding, we
                must provide you with written notice of such waiver through one
                of our authorized representatives. Headings: The section and
                paragraph headings in these Terms of Use are for convenience
                only and shall not affect their interpretation.
              </p>
              <p>
                19. Gym Nerd DMCA Copyright Policy Gym Nerd has adopted the
                following general policy toward copyright infringement in
                accordance with the Digital Millennium Copyright Act. The
                address of the Designated Agent to Receive Notification of
                Claimed Infringement ("Designated Agent") is listed at the end
                of this policy. Procedure for Reporting Copyright Infringement.
                If you believe that material or content residing on or
                accessible through Gym Nerd's websites or services infringes a
                copyright, please send a notice of copyright infringement
                containing the following information to the Designated Agent
                listed below: A physical or electronic signature of a person
                authorized to act on behalf of the owner of the copyright that
                has been allegedly infringed; Identification of works or
                materials being infringed; Identification of the material that
                is claimed to be infringing including information regarding the
                location of the infringing materials that the copyright owner
                seeks to have removed, with sufficient detail so that Gym Nerd
                is capable of finding and verifying its existence; Contact
                information about the notifier including address, telephone
                number and, if available, e-mail address; A statement that the
                notifier has a good faith belief that the material is not
                authorized by the copyright owner, its agent, or the law; and A
                statement made under penalty of perjury that the information
                provided is accurate and the notifying party is authorized to
                make the complaint on behalf of the copyright owner.
              </p>

              <p className="text-wrap">
                Please contact{' '}
                <a href="mailto:support@gymnerd.app?subject=Privacy%20Policy">
                  support@gymnerd.app
                </a>{' '}
                for any claimed infringement for Gym Nerd.
              </p>

              <p className="text-wrap">
                Kickbush Holdings, LLC
                <br />
                ATTN: Adam Kickbush
                <br />
                2046 Shavano Pl
                <br />
                St George, UT 84790
              </p>
            </div>
            <div className="col-sm-1" />
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
