import React, { Component } from 'react';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';

import Header from '../Header';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
      message: '',
      messageError: '',
      disabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      disabled: true
    });
    const { email, message } = this.state;
    const errors = {};
    if (!message) {
      errors.message = 'Please enter message';
    }
    if (!email) {
      errors.email = 'Please enter email';
    }
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      errors.email = 'Email invalid';
    }
    if (Object.keys(errors).length === 0) {
      this.props.sendMessage({ email, message });
      this.setState({
        disabled: false,
        message: '',
        email: ''
      });
    } else {
      this.setState({
        disabled: false,
        emailError: errors.email,
        messageError: errors.message
      });
    }
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: ''
    });
  }
  renderAlert() {
    const { messageError, messageSent } = this.props;
    if (messageError) {
      return (
        <div
          className="alert alert-danger text-center"
          style={{ marginTop: 20 }}
        >
          <strong>{messageError}</strong>
        </div>
      );
    } else if (messageSent) {
      return (
        <div
          className="alert alert-success text-center"
          style={{ marginTop: 20 }}
        >
          <strong>{messageSent}</strong>
        </div>
      );
    }
  }

  render() {
    const { email, message, emailError, messageError, disabled } = this.state;
    return (
      <div className="clearfix">
        <Header title="Contact Us" />
        <div className="container" style={{ paddingTop: 40 }}>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-3" />
            <div className="col-lg-6 col-xs-12">
              <form className="my-form" onSubmit={this.handleSubmit}>
                <fieldset className="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email:"
                    value={email}
                    className="input-effect"
                    onChange={this.handleChange}
                  />
                  <span className="focus-border" />
                  {emailError && (
                    <span className="text-danger">{emailError}</span>
                  )}
                </fieldset>

                <fieldset className="form-group">
                  <textarea
                    type="textarea"
                    name="message"
                    rows="5"
                    placeholder="Message:"
                    value={message}
                    className="input-effect"
                    onChange={this.handleChange}
                  />
                  <span className="focus-border" />
                  {messageError && (
                    <span className="text-danger">{messageError}</span>
                  )}
                </fieldset>

                {this.renderAlert()}
                <button
                  action="submit"
                  className="btn btn-gymnerd"
                  disabled={disabled}
                  type="reset"
                >
                  Send Message
                </button>
              </form>
            </div>
            <div className="col-lg-3" />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    messageError: state.auth.error,
    messageSent: state.auth.success
  };
}

export default connect(
  mapStateToProps,
  actions
)(Contact);
