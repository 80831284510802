import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  FETCH_DATA,
  FETCH_DATA_FAIL,
  LOADING,
  MESSAGE_FAIL,
  MESSAGE_SENT,
  STRIPE_SUCCESS,
  STRIPE_FAIL,
  RESET_ERROR,
  CLEAR_SUCCESS,
  RESET_PASSWORD,
  RESET_SUCCESS,
  STRIPE_UPDATE_SUCCESS,
  STRIPE_UPDATE_FAIL,
  EMAIL_UPDATE_SUCCESS,
  EMAIL_UPDATE_FAIL,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAIL,
  ACCOUNT_CANCEL_SUCCESS,
  ACCOUNT_CANCEL_FAIL,
  PROMO_VALID,
  PROMO_FAIL
} from '../types';

export default function(state = {}, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        authenticated: true,
        error: '',
        email: action.payload
      };
    case UNAUTH_USER:
      return { ...state, authenticated: false };
    case AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        data: '',
        reset: '',
        success: '',
        loading: false
      };
    case FETCH_DATA:
      if (action.payload.appleReceipt) {
        return {
          ...state,
          fname: action.payload.fname,
          lname: action.payload.lname,
          email: action.payload.email,
          apple: action.payload.appleReceipt,
          membership: 'Membership Controlled By Apple',
          loading: false
        };
      } else {
        return {
          ...state,
          fname: action.payload.fname,
          lname: action.payload.lname,
          email: action.payload.email,
          status: action.payload.role,
          membership: action.payload.membership,
          sub: action.payload.subscription,
          last4: action.payload.last4,
          month: action.payload.month,
          year: action.payload.year,
          loading: false
        };
      }
    case FETCH_DATA_FAIL:
      return { ...state, fetchError: action.payload };
    case LOADING:
      return { ...state, loading: true };
    case RESET_PASSWORD:
      return {
        ...state,
        reset: action.payload,
        note: 'Check spam just in case',
        error: '',
        loading: false
      };
    case RESET_SUCCESS:
      return { ...state, success: action.payload, error: '', loading: false };
    case STRIPE_SUCCESS:
      return {
        ...state,
        stripeSuccess: action.payload,
        error: '',
        loading: false
      };
    case STRIPE_FAIL:
      return { ...state, stripeError: action.payload, loading: false };
    case STRIPE_UPDATE_SUCCESS:
      return { ...state, success: action.payload, loading: false };
    case STRIPE_UPDATE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case EMAIL_UPDATE_SUCCESS:
      return { ...state, success: action.payload, loading: false };
    case EMAIL_UPDATE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case ACCOUNT_CANCEL_SUCCESS:
      return { ...state, success: action.payload, loading: false };
    case ACCOUNT_CANCEL_FAIL:
      return { ...state, error: action.payload, loading: false };
    case MESSAGE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case MESSAGE_SENT:
      return { ...state, success: action.payload, error: '', loading: false };
    case RESET_ERROR:
      return { ...state, error: '', loading: false };
    case CLEAR_SUCCESS:
      return { ...state, success: '', loading: false };
    case PASSWORD_UPDATE_SUCCESS:
      return { ...state, success: action.payload, loading: false };
    case PASSWORD_UPDATE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case PROMO_VALID:
      return { ...state, promoValid: action.payload, promoFail: '' };
    case PROMO_FAIL:
      return { ...state, promoFail: action.payload, promoValid: '' };
    default:
      return { ...state };
  }
}
