import React, { Component } from 'react';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import { Oval as Loading } from '../../loaders';

class Cancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verify: '',
      verifyError: '',
      disabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      disabled: true
    });
    const { verify } = this.state;
    const errors = {};
    if (!verify) {
      errors.verify = '';
    }
    if (verify !== 'GYMNERD') {
      errors.verify = 'Please enter verification';
    }
    if (Object.keys(errors).length === 0) {
      this.props.cancel();
      this.setState({
        disabled: false,
        verify: ''
      });
    } else {
      this.setState({
        disabled: false,
        verifyError: errors.verify
      });
    }
  }
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: ''
    });
  }

  renderInput({ type, label, meta: { touched, error, warning }, ...field }) {
    return (
      <fieldset className="form-group">
        <input
          {...field.input}
          type={type}
          placeholder={label}
          className="input-effect"
        />
        <span className="focus-border" />
        {touched && error && <span className="text-danger">{error}</span>}
      </fieldset>
    );
  }
  renderAlert() {
    if (this.props.cancelFail) {
      return (
        <div className="alert alert-danger" style={{ marginTop: 20 }}>
          <strong>{this.props.cancelFail}</strong>
        </div>
      );
    }
  }
  renderSuccess() {
    if (this.props.cancelSuccess) {
      return (
        <div className="alert alert-success" style={{ marginTop: 20 }}>
          <strong>{this.props.cancelSuccess}</strong>
        </div>
      );
    }
  }
  renderContent() {
    if (this.props.loading) {
      return (
        <div className="text-center">
          <Loading className="oval-loader" />
        </div>
      );
    } else {
      return (
        <div className="row d-flex justify-content-center">
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <form onSubmit={this.handleSubmit} className="my-form">
              <fieldset className="form-group">
                <label>Enter 'GYMNERD' to verify cancellation:</label>
                <input
                  type="text"
                  name="verify"
                  placeholder="GYMNERD"
                  value={this.state.verify}
                  className="input-effect"
                  onChange={this.handleInputChange}
                />
                <span className="focus-border" />
              </fieldset>
              <button
                action="submit"
                className="btn btn-outline-danger"
                disabled={this.state.disabled}
                style={{ fontWeight: 'bold', width: '100%' }}
              >
                Cancel
              </button>
              {this.renderAlert()}
              {this.renderSuccess()}
            </form>
          </div>
          <div className="col-lg-3" />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="container">
        <section style={{ paddingTop: 40 }}>
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12">
              <h1>Cancel Your Gym Nerd</h1>
              <p>
                This will cancel your account at the end of this billing cycle.
              </p>
            </div>
          </div>
        </section>

        <section>{this.renderContent()}</section>

        <section style={{ paddingBottom: 40 }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    cancelFail: state.auth.error,
    cancelSuccess: state.auth.success
  };
}

export default connect(
  mapStateToProps,
  actions
)(Cancel);
