export const AUTH_USER = 'auth_user';
export const UNAUTH_USER = 'unauth_user';
export const AUTH_ERROR = 'auth_error';

export const FETCH_DATA = 'fetch_data';
export const FETCH_DATA_FAIL = 'fetch_data_fail';

export const MESSAGE_SENT = 'message_sent';
export const MESSAGE_FAIL = 'message_fail';

export const LOADING = 'loading';
export const STRIPE_SUCCESS = 'stripe_success';
export const STRIPE_FAIL = 'stripe_fail';

export const SET_STRIPE_TOKEN = 'set_stripe_token';
export const SET_EMAIL = 'set_email';

export const RESET_ERROR = 'reset_error';
export const CLEAR_SUCCESS = 'clear_success';
export const RESET_PASSWORD = 'reset_password';
export const RESET_SUCCESS = 'reset_success';

export const PROMO_VALID = 'promo_valid';
export const PROMO_FAIL = 'promo_fail';

export const STRIPE_UPDATE_SUCCESS = 'stripe_update_success';
export const STRIPE_UPDATE_FAIL = 'stripe_update_fail';
export const EMAIL_UPDATE_SUCCESS = 'email_update_success';
export const EMAIL_UPDATE_FAIL = 'email_update_fail';
export const PASSWORD_UPDATE_SUCCESS = 'password_update_success';
export const PASSWORD_UPDATE_FAIL = 'password_update_fail';
export const ACCOUNT_CANCEL_SUCCESS = 'account_cancel_succuess';
export const ACCOUNT_CANCEL_FAIL = 'account_cancel_fail';
