import { combineReducers } from 'redux';
import authReducer from './auth_reducer';
import stripeReducer from './stripe_reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  stripe: stripeReducer
});

export default rootReducer;
