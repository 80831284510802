import React, { Component } from 'react';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import { Oval as Loading } from '../../loaders';

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordError: '',
      passwordConfirm: '',
      passwordConfirmError: '',
      disabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      disabled: true
    });
    const { password, passwordConfirm } = this.state;
    const errors = {};
    if (!password) {
      errors.password = 'Please enter password';
    }
    if (!passwordConfirm) {
      errors.passwordConfirm = 'Please enter password confirmation';
    }
    if (password !== passwordConfirm) {
      errors.passwordConfirm = 'Passwords must match';
    }
    if (Object.keys(errors).length === 0) {
      this.props.changePassword({ password });
      this.setState({
        disabled: false,
        password: '',
        passwordConfirm: ''
      });
    } else {
      this.setState({
        disabled: false,
        passwordError: errors.password,
        passwordConfirmError: errors.passwordConfirm
      });
    }
  }
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: ''
    });
  }
  renderAlert() {
    if (this.props.passwordFail) {
      return (
        <div className="alert alert-danger" style={{ marginTop: 20 }}>
          <strong>{this.props.passwordFail}</strong>
        </div>
      );
    }
  }
  renderSuccess() {
    if (this.props.passwordSuccess) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
          style={{ marginTop: 20 }}
        >
          <strong>{this.props.passwordSuccess}</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            style={{ textAlign: 'right' }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }
  renderContent() {
    if (this.props.loading) {
      return (
        <div className="text-center">
          <Loading className="oval-loader" />
        </div>
      );
    } else {
      return (
        <div className="row d-flex justify-content-center">
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <form onSubmit={this.handleSubmit} className="my-form">
              <fieldset className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Password:"
                  value={this.state.password}
                  className="input-effect"
                  onChange={this.handleInputChange}
                />
                <span className="focus-border" />
                {this.state.passwordError && (
                  <span className="text-danger">
                    {this.state.passwordError}
                  </span>
                )}
              </fieldset>
              <fieldset className="form-group">
                <input
                  type="password"
                  name="passwordConfirm"
                  placeholder="Confirm Password:"
                  value={this.state.passwordConfirm}
                  className="input-effect"
                  onChange={this.handleInputChange}
                />
                <span className="focus-border" />
                {this.state.passwordConfirmError && (
                  <span className="text-danger">
                    {this.state.passwordConfirmError}
                  </span>
                )}
              </fieldset>
              <button
                action="submit"
                className="btn btn-gymnerd"
                disabled={this.state.disabled}
              >
                Update Password
              </button>
              {this.renderAlert()}
              {this.renderSuccess()}
            </form>
          </div>
          <div className="col-lg-3" />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="container">
        <section style={{ paddingTop: 40 }}>
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12">
              <p style={Styles.section}>Update Your Password</p>
            </div>
          </div>
        </section>
        {this.renderContent()}
        <div className="clearfix" />
      </div>
    );
  }
}

const Styles = {
  section: {
    fontFamily: 'black_ops_oneregular',
    fontSize: 30,
    fontWeight: 'bold'
  }
};

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    passwordSuccess: state.auth.success,
    passwordFail: state.auth.error
  };
}

export default connect(
  mapStateToProps,
  actions
)(UpdatePassword);
