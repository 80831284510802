import React, { Component } from 'react';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Oval as Loading } from '../../loaders';

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      disabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      disabled: true
    });
    const { email, password } = this.state;
    const errors = {};
    if (!password) {
      errors.password = 'Please enter password';
    }
    if (!email) {
      errors.email = 'Please enter email';
    }
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      errors.email = 'Email invalid';
    }
    if (Object.keys(errors).length === 0) {
      this.props.signinUser({ email, password });
      this.setState({
        disabled: false,
        name: '',
        email: ''
      });
    } else {
      this.setState({
        disabled: false,
        emailError: errors.email,
        passwordError: errors.password
      });
    }
  }
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: ''
    });
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger" style={{ marginTop: 20 }}>
          <strong>{this.props.errorMessage}</strong>
        </div>
      );
    }
  }
  renderContent() {
    if (this.props.loading) {
      return (
        <div className="text-center">
          <Loading className="oval-loader" />
        </div>
      );
    } else {
      return (
        <div className="row d-flex justify-content-center">
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <form onSubmit={this.handleSubmit} className="my-form">
              <fieldset className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email:"
                  value={this.state.email}
                  className="input-effect"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                />
                <span className="focus-border" />
                {this.state.emailError && (
                  <span className="text-danger">{this.state.emailError}</span>
                )}
              </fieldset>
              <fieldset className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Password:"
                  value={this.state.password}
                  className="input-effect"
                  onChange={this.handleInputChange}
                />
                <span className="focus-border" />
                {this.state.passwordError && (
                  <span className="text-danger">
                    {this.state.passwordError}
                  </span>
                )}
              </fieldset>
              {this.renderAlert()}
              <button
                action="submit"
                className="btn btn-gymnerd"
                disabled={this.state.disabled}
              >
                Sign In
              </button>
            </form>
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: 15 }}
            >
              <Link to="/forgotpassword" className="gym-nerd-link">
                Forgot Passord?
              </Link>
            </div>
          </div>
          <div className="col-lg-3" />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="container">
        <section style={{ paddingTop: 40 }}>
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12">
              <p style={Styles.section}>Please Sign In</p>
            </div>
          </div>
        </section>
        <section>{this.renderContent()}</section>
        <div className="clearfix" />
      </div>
    );
  }
}

const Styles = {
  section: {
    fontSize: 30,
    fontWeight: 'bold'
  }
};

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    errorMessage: state.auth.error
  };
}

export default connect(
  mapStateToProps,
  actions
)(Signin);
