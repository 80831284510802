import React from 'react';
import { CardElement } from 'react-stripe-elements';

class CardSectionUpdate extends React.Component {
  render() {
    return (
      <div className="form-group">
        <fieldset className="form-group">
          <CardElement
            style={{
              base: {
                iconColor: '#666EE8',
                color: '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',

                ':focus': {
                  color: '#424770'
                },

                '::placeholder': {
                  color: '#9BACC8'
                },

                ':focus::placeholder': {
                  color: '#CFD7DF'
                }
              },
              invalid: {
                color: '#fff',
                ':focus': {
                  color: '#FA755A'
                },
                '::placeholder': {
                  color: '#FFCCA5'
                }
              }
            }}
          />
        </fieldset>
      </div>
    );
  }
}

export default CardSectionUpdate;
