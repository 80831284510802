import { SET_STRIPE_TOKEN, SET_EMAIL } from '../types';

export default function(state = {}, action) {
  switch (action.type) {
    case SET_STRIPE_TOKEN:
      return { ...state, token: action.payload };
    case SET_EMAIL:
      return { ...state, email: action.payload };
    default:
      return { ...state };
  }
}
