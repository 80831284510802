import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';

import './nav.css';
import './hamburger.css';
import { User, SignIn, SignOut, Envelope } from '../../fontawesome';

class MyNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closed: true,
      showDropDown: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
  }
  handleWindowResize = () => {
    const mynav = document.getElementById('mynav');
    const mylogo = document.getElementById('mylogo');
    const toggler = document.getElementById('toggler');
    if (window.innerWidth < 576) {
      mynav.classList.add('shrink');
      mylogo.classList.add('img-shrink');
      toggler.classList.add('ham-shrink');
    } else {
      mynav.classList.remove('shrink');
      mylogo.classList.remove('img-shrink');
      toggler.classList.remove('ham-shrink');
    }
  };
  componentDidMount() {
    const mynav = document.getElementById('mynav');
    const mylogo = document.getElementById('mylogo');
    const toggler = document.getElementById('toggler');
    if (window.innerWidth < 576) {
      mynav.classList.add('shrink');
      mylogo.classList.add('img-shrink');
      toggler.classList.add('ham-shrink');
    } else {
      mynav.classList.remove('shrink');
      mylogo.classList.remove('img-shrink');
      toggler.classList.remove('ham-shrink');
    }
    window.addEventListener('resize', this.handleWindowResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
  handleClickOutside = evt => {
    const { closed } = this.state;
    const toggler = document.getElementById('toggler');
    if (!closed) {
      toggler.classList.remove('open');
      this.setState({ closed: true, showDropDown: false });
    }
  };
  handleClick(e) {
    e.preventDefault();
    const toggler = document.getElementById('toggler');
    const { closed } = this.state;
    if (closed) {
      toggler.classList.add('open');
      this.setState({ closed: false, showDropDown: true });
    } else {
      toggler.classList.remove('open');
      this.setState({ closed: true, showDropDown: false });
    }
  }
  handleSignOut() {
    this.props.signOutUser();
  }
  renderAuthNav() {
    const { authenticated } = this.props;
    if (authenticated) {
      return (
        <Fragment>
          <li className="nav-item align-self-center">
            <Link className="nav-link nav-hov float-right" to="/profile">
              <User className="nav-svg" />
              PROFILE
            </Link>
          </li>
          <li className="nav-item align-self-center">
            <Link
              className="nav-link nav-hov float-right"
              to="/"
              onClick={this.handleSignOut}
            >
              <SignOut className="nav-svg" />
              SIGN OUT
            </Link>
          </li>
        </Fragment>
      );
    } else {
      return (
        <li className="nav-item align-self-center">
          <Link className="nav-link nav-hov float-right" to="/signin">
            <SignIn className="nav-svg" />
            SIGN IN
          </Link>
        </li>
      );
    }
  }
  render() {
    const { showDropDown } = this.state;
    return (
      <nav id="mynav" className="navbar navbar-expand-sm transparent">
        <div className="row nav-row">
          <a className="navbar-brand align-self-center" href="/">
            <img
              id="mylogo"
              className="align-self-center"
              src="https://d22448hko77q9b.cloudfront.net/headB.png"
              alt="GymNerd Logo"
              height="55"
            />
          </a>
          <button
            className="navbar-toggler my-toggler"
            id="toggler"
            onClick={this.handleClick}
          >
            <span className="hamburger" />
          </button>
          <div className="collapse navbar-collapse justify-content-end">
            <ul className="navbar-nav">
              {this.renderAuthNav()}
              <li className="nav-item align-self-center">
                <Link className="nav-link nav-hov float-right" to="/contact">
                  <Envelope className="nav-svg" />
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="navbar-brand align-self-center"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://itunes.apple.com/us/app/gym-nerd/id1448116169?mt=8"
                >
                  <img
                    className="align-self-center"
                    src="https://d22448hko77q9b.cloudfront.net/apple.png"
                    alt="Apple"
                    height="45"
                  />
                </a>
              </li>
            </ul>
          </div>
          {showDropDown ? (
            <div className="nav-show">
              <ul className="navbar-nav">
                {this.renderAuthNav()}
                <li className="nav-item align-self-center">
                  <Link className="nav-link nav-hov float-right" to="/contact">
                    <Envelope className="nav-svg" />
                    Contact
                  </Link>
                </li>
                <li className="nav-item text-center">
                  <a
                    className="align-self-center"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://itunes.apple.com/us/app/gym-nerd/id1448116169?mt=8"
                  >
                    <img
                      className="align-self-center"
                      src="https://d22448hko77q9b.cloudfront.net/apple.png"
                      alt="Apple"
                      height="45"
                    />
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated
  };
}

export default connect(
  mapStateToProps,
  actions
)(onClickOutside(MyNav));
