import React, { Component } from 'react';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import { Oval as Loading } from '../../loaders';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
      disabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      disabled: true
    });
    const { email } = this.state;
    const errors = {};
    if (!email) {
      errors.email = 'Please enter email';
    }
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      errors.email = 'Email invalid';
    }
    if (Object.keys(errors).length === 0) {
      this.props.forgotPassword({ email });
      this.setState({
        disabled: false,
        email: ''
      });
    } else {
      this.setState({
        disabled: false,
        emailError: errors.email
      });
    }
  }
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: ''
    });
  }
  renderAlert() {
    if (this.props.errorReset) {
      return (
        <div className="alert alert-danger" style={{ marginTop: 20 }}>
          <strong>Oops!</strong> {this.props.errorReset}
        </div>
      );
    }
  }
  renderResponse() {
    if (this.props.reset) {
      return (
        <div className="alert alert-success" style={{ marginTop: 20 }}>
          <strong>{this.props.reset}</strong>
        </div>
      );
    }
  }
  renderNote() {
    if (this.props.note) {
      return (
        <div className="alert alert-secondary" style={{ marginTop: 20 }}>
          <strong>{this.props.note}</strong>
        </div>
      );
    }
  }
  renderContent() {
    if (this.props.loading) {
      return (
        <div className="text-center">
          <Loading className="oval-loader" />
        </div>
      );
    } else {
      return (
        <div className="row d-flex justify-content-center">
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <form onSubmit={this.handleSubmit} className="my-form">
              <fieldset className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email:"
                  value={this.state.email}
                  className="input-effect"
                  onChange={this.handleInputChange}
                />
                <span className="focus-border" />
                {this.state.emailError && (
                  <span className="text-danger">{this.state.emailError}</span>
                )}
              </fieldset>
              <button
                action="submit"
                className="btn btn-gymnerd"
                disabled={this.state.disabled}
              >
                Submit
              </button>
              {this.renderAlert()}
              {this.renderResponse()}
              {this.renderNote()}
            </form>
          </div>
          <div className="col-lg-3" />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="container">
        <section style={{ paddingTop: 40 }}>
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12">
              <p style={Styles.section}>Recover Password</p>
            </div>
          </div>
        </section>

        <section>{this.renderContent()}</section>
        <div className="clearfix" />
      </div>
    );
  }
}

const Styles = {
  section: {
    fontFamily: 'black_ops_oneregular',
    fontSize: 30,
    fontWeight: 'bold'
  }
};

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    errorReset: state.auth.error,
    reset: state.auth.reset,
    note: state.auth.note
  };
}

export default connect(
  mapStateToProps,
  actions
)(ForgotPassword);
