import React from 'react';
import Header from './components/common/Header';

const App = () => {
  return (
    <div>
      <Header title="YOUR workouts when YOU need them" />
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col">
            <section className="features">
              <h1>Gym Nerd is now in the Apple App Store!</h1>
              <a
                className="text-center"
                rel="noopener noreferrer"
                target="_blank"
                href="https://itunes.apple.com/us/app/gym-nerd/id1448116169?mt=8"
              >
                <img
                  className="align-self-center"
                  src="https://d22448hko77q9b.cloudfront.net/apple.png"
                  alt="Apple"
                  height="75"
                />
              </a>
              <p className="pt-3">
                Gym Nerd will be coming to the Google Play store soon!
              </p>
            </section>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <section className="features">
              <h1>What can Gym Nerd do for you?</h1>
              <p>
                Gym Nerd is here to make sure you never struggle to have a great
                workout again. It structures your workouts based off selections
                you make. Allowing you to begin your workout in as little as two
                button pushes.
              </p>
              <p>
                Gym Nerd maximizes upon Y3T training style which guides you
                through a lower rep/heavier style week. Then a medium
                rep/moderate weight week. And then a high rep/lower weight week.
              </p>
              <p>
                You may though use Gym Nerd in anyway you see fit and you will
                have access to hundreds of workouts within the Gym Nerd
                database.
              </p>
            </section>
          </div>
        </div>
      </div>

      <section className="features">
        <div className="row black-bg">
          <div className="col">
            <h1 className="white">Using Gym Nerd</h1>
          </div>
        </div>
        <div className="row clearfix black-bg">
          <div className="feature-card col-xs-12 col-sm-12 col-md-6 col-lg-4 float-left">
            <img
              className="screen"
              src="https://d22448hko77q9b.cloudfront.net/style5.5.png"
              alt="Style"
            />
            <img
              className="phone"
              src="https://d22448hko77q9b.cloudfront.net/phone.png"
              srcSet="https://d22448hko77q9b.cloudfront.net/phone%402x.png"
              alt="Login Phone iPhone"
            />
            <div className="feature-info">
              <h2>Select Style</h2>
            </div>
          </div>

          <div className="feature-card col-xs-12 col-sm-12 col-md-6 col-lg-4 float-left">
            <img
              className="screen"
              src="https://d22448hko77q9b.cloudfront.net/bodypart5.5.png"
              alt="PWA"
            />
            <img
              className="phone"
              src="https://d22448hko77q9b.cloudfront.net/phone.png"
              srcSet="https://d22448hko77q9b.cloudfront.net/phone%402x.png"
              alt="Login iPhone"
            />
            <div className="feature-info">
              <h2>Select Body Part</h2>
            </div>
          </div>

          <div className="feature-card col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
            <img
              className="screen"
              src="https://d22448hko77q9b.cloudfront.net/display5.5.png"
              alt="Ready"
            />
            <img
              className="phone"
              src="https://d22448hko77q9b.cloudfront.net/phone.png"
              srcSet="https://d22448hko77q9b.cloudfront.net/phone%402x.png"
              alt="Info iPhone"
            />
            <div className="feature-info">
              <h2>Use Workout!</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="features">
        <div className="row">
          <div className="col">
            <h1>Why Chose Gym Nerd?</h1>
          </div>
        </div>
        <div className="row clearfix">
          <div className="feature-card col-xs-12 col-sm-12 col-md-6 col-lg-4 float-left">
            <img
              className="screen"
              src="https://d22448hko77q9b.cloudfront.net/video5.5.png"
              alt="Reps"
            />
            <img
              className="phone"
              src="https://d22448hko77q9b.cloudfront.net/phoneBlack.png"
              srcSet="https://d22448hko77q9b.cloudfront.net/phoneBlack%402x.png"
              alt="Random iPhone"
            />
            <div className="feature-info">
              <h2>Video Instruction</h2>
            </div>
          </div>

          <div className="feature-card col-xs-12 col-sm-12 col-md-6 col-lg-4 float-left">
            <img
              className="screen"
              src="https://d22448hko77q9b.cloudfront.net/abs5.5.png"
              alt="Bodypart"
            />
            <img
              className="phone"
              src="https://d22448hko77q9b.cloudfront.net/phoneBlack.png"
              srcSet="https://d22448hko77q9b.cloudfront.net/phoneBlack%402x.png"
              alt="Combo iPhone"
            />
            <div className="feature-info">
              <h2>Ab Training</h2>
            </div>
          </div>

          <div className="feature-card col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
            <img
              className="screen"
              src="https://d22448hko77q9b.cloudfront.net/complete5.5.png"
              alt="Workouts"
            />
            <img
              className="phone"
              src="https://d22448hko77q9b.cloudfront.net/phoneBlack.png"
              srcSet="https://d22448hko77q9b.cloudfront.net/phoneBlack%402x.png"
              alt="Macro iPhone"
            />
            <div className="feature-info">
              <h2>Proper Form</h2>
            </div>
          </div>
        </div>
      </section>
      <div className="clearfix" />
    </div>
  );
};

export default App;
