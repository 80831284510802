import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../../config/history';
import * as actions from '../../../redux/actions';
import { Puff as Loading } from '../../loaders';

class Dashboard extends Component {
  componentWillMount() {
    this.props.fetchUserInformation();
  }
  handleUpdateEmail() {
    history.push('/emailupdate');
  }
  handleUpdatePassword() {
    history.push('/passwordupdate');
  }
  handleCancel() {
    history.push('/cancel');
  }
  handleUpdateCard() {
    localStorage.setItem('email', this.props.email);
    history.push('/cardupdate');
  }
  renderError() {
    if (this.props.fetchError) {
      return (
        <div
          className="alert alert-danger"
          style={{ marginTop: 20, textAlign: 'center' }}
        >
          <strong>{this.props.fetchError}</strong>
        </div>
      );
    }
  }

  renderContent() {
    const {
      loading,
      apple,
      fname,
      lname,
      email,
      status,
      subscription,
      membership,
      last4,
      month,
      year
    } = this.props;
    if (loading) {
      return (
        <div className="text-center">
          <Loading className="oval-loader" />
        </div>
      );
    } else if (apple) {
      return (
        <div>
          <p>
            User:{' '}
            <span className="dashboard-font">
              {fname} {lname}
            </span>
          </p>
          <p>
            Email: <span className="dashboard-font">{email}</span>
          </p>
          <button
            onClick={this.handleUpdateEmail.bind(this)}
            className="btn btn-outline-success btn-sm"
          >
            Update Email
          </button>
          <span style={{ paddingRight: 10 }} />
          <button
            onClick={this.handleUpdatePassword.bind(this)}
            className="btn btn-outline-success btn-sm"
          >
            Update Password
          </button>
          <hr />
          <p>
            Membership:{' '}
            <span
              className="dashboard-font"
              style={{ textTransform: 'uppercase' }}
            >
              {membership}
            </span>
          </p>
          <hr />
        </div>
      );
    } else {
      return (
        <div>
          <p>
            User:{' '}
            <span className="dashboard-font">
              {fname} {lname}
            </span>
          </p>
          <p>
            Email: <span className="dashboard-font">{email}</span>
          </p>
          <button
            onClick={this.handleUpdateEmail.bind(this)}
            className="btn btn-outline-success btn-sm"
          >
            Update Email
          </button>
          <span style={{ paddingRight: 10 }} />
          <button
            onClick={this.handleUpdatePassword.bind(this)}
            className="btn btn-outline-success btn-sm"
          >
            Update Password
          </button>
          <hr />
          <p>
            App Rights: <span className="dashboard-font">{status}</span>
          </p>
          <p>
            Subscription: <span className="dashboard-font">{subscription}</span>
          </p>
          <p>
            Membership Status:{' '}
            <span
              className="dashboard-font"
              style={{ textTransform: 'uppercase' }}
            >
              {membership}
            </span>
          </p>
          <hr />
          <p>
            <u>Current Card On File</u>
          </p>
          <p>
            Ends In: <span className="dashboard-font">{last4}</span>
          </p>
          <p>
            Expires:{' '}
            <span className="dashboard-font">
              {month} / {year}
            </span>
          </p>
          <button
            onClick={this.handleUpdateCard.bind(this)}
            className="btn btn-outline-success btn-sm"
            style={{ width: '100%' }}
          >
            Update Card
          </button>
          <hr />
          <button
            onClick={this.handleCancel.bind(this)}
            className="btn btn-outline-danger btn-sm"
            style={{ width: '100%' }}
          >
            Cancel GymNerd
          </button>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <div className="row d-flex justify-content-center">
          <div className="col-m-2" />
          <div className="col-m-8" style={{ minWidth: 350 }}>
            <div className="container">
              <section style={{ paddingTop: 40 }}>
                <div className="row d-flex justify-content-center">
                  <div className="col-xs-12">
                    <h1 className="text-center">Your GymNerd Profile</h1>
                  </div>
                </div>
              </section>

              {this.renderContent()}
              {this.renderError()}

              <section style={{ paddingBottom: 40 }} />
            </div>
          </div>
          <div className="col-m-2" />
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchError: state.auth.fetchError,
    loading: state.auth.loading,
    fname: state.auth.fname,
    lname: state.auth.lname,
    email: state.auth.email,
    status: state.auth.status,
    membership: state.auth.membership,
    subscription: state.auth.sub,
    last4: state.auth.last4,
    month: state.auth.month,
    year: state.auth.year,
    apple: state.auth.apple
  };
}

export default connect(
  mapStateToProps,
  actions
)(Dashboard);
