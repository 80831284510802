import React from 'react';
import ScriptLoader from 'react-script-loader-hoc';
import { StripeProvider, Elements } from 'react-stripe-elements';
import InjectedUpdateForm from './injected_update_form.js';
import { Puff as Loading } from '../loaders';

import { STRIPE_PK_LIVE } from '../../config';

const UpdateCard = ({ scriptsLoadedSuccessfully }) => {
  if (!scriptsLoadedSuccessfully) {
    return (
      <div className="text-center">
        <Loading className="oval-loader" />
      </div>
    );
  } else {
    return (
      <div className="row d-flex justify-content-center">
        <div className="col-lg-3" />
        <div className="col-lg-6">
          <StripeProvider apiKey={STRIPE_PK_LIVE}>
            <Elements>
              <InjectedUpdateForm />
            </Elements>
          </StripeProvider>
        </div>
        <div className="col-lg-3" />
      </div>
    );
  }
};

export default ScriptLoader('https://js.stripe.com/v3/')(UpdateCard);
