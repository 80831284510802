import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from 'react-router-dom';

import './footer.css';
import { User, SignIn, SignOut, Facebook, Instagram } from '../../fontawesome';

class MyFooter extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.props.signOutUser();
  }
  render() {
    const { authenticated } = this.props;
    return (
      <footer className="my-footer container-fluid">
        <div className="row d-flex justify-content-center footer-top footer-pad-control">
          <div className="col">
            {authenticated ? (
              ((
                <Link className="footer-hov" to="/profile">
                  <User className="footer-svg" />
                </Link>
              ),
              (
                <Link className="footer-hov" to="/" onClick={this.handleClick}>
                  <SignOut className="footer-svg" />
                </Link>
              ))
            ) : (
              <Link className="footer-hov" to="/signin">
                <SignIn className="footer-svg" />
              </Link>
            )}
            <a
              href="https://www.instagram.com/gymnerdapp/"
              target="_top"
              className="footer-hov"
            >
              <Instagram className="footer-svg" />
            </a>
            <a
              href="https://www.facebook.com/gymnerdapp/"
              target="_top"
              className="footer-hov"
            >
              <Facebook className="footer-svg" />
            </a>
            <hr className="whiteHr" />
          </div>
        </div>

        <div className="row d-flex justify-content-center footer-top footer-pad-control">
          <div className="col">
            <Link className="footer-hov" to="/privacy">
              PRIVACY POLICY
            </Link>
            <hr className="whiteHr" />
          </div>
        </div>

        <div className="row d-flex justify-content-center footer-bottom footer-pad-control">
          <div className="col">
            COPYRIGHT &copy; {new Date().getFullYear()} GYMNERD
          </div>
        </div>
      </footer>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated
  };
}

export default connect(
  mapStateToProps,
  actions
)(MyFooter);
