import React, { Component } from 'react';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import { Oval as Loading } from '../../loaders';

class UpdateEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
      emailConfirm: '',
      emailConfirmError: '',
      disabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      disabled: true
    });
    const { email, emailConfirm } = this.state;
    const errors = {};
    if (!email) {
      errors.email = 'Please enter password';
    }
    if (!emailConfirm) {
      errors.emailConfirm = 'Please enter password confirmation';
    }
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      errors.email = 'Email invalid';
    }
    if (email !== emailConfirm) {
      errors.emailConfirm = 'Emails must match';
    }
    if (Object.keys(errors).length === 0) {
      this.props.changeEmail({ email });
      this.setState({
        disabled: false,
        email: '',
        emailConfirm: ''
      });
    } else {
      this.setState({
        disabled: false,
        emailError: errors.email,
        emailConfirmError: errors.emailConfirm
      });
    }
  }
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: ''
    });
  }
  renderAlert() {
    if (this.props.emailFail) {
      return (
        <div className="alert alert-danger" style={{ marginTop: 20 }}>
          <strong>{this.props.emailFail}</strong>
        </div>
      );
    }
  }

  renderSuccess() {
    if (this.props.emailSuccess) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
          style={{ marginTop: 20 }}
        >
          <strong>{this.props.emailSuccess}</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            style={{ textAlign: 'right' }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  renderContent() {
    if (this.props.loading) {
      return (
        <div className="text-center">
          <Loading className="oval-loader" />
        </div>
      );
    } else {
      return (
        <div className="row d-flex justify-content-center">
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <form onSubmit={this.handleSubmit} className="my-form">
              <fieldset className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email:"
                  value={this.state.email}
                  className="input-effect"
                  onChange={this.handleInputChange}
                />
                <span className="focus-border" />
                {this.state.emailError && (
                  <span className="text-danger">{this.state.emailError}</span>
                )}
              </fieldset>
              <fieldset className="form-group">
                <input
                  type="email"
                  name="emailConfirm"
                  placeholder="Confirm Email:"
                  value={this.state.emailConfirm}
                  className="input-effect"
                  onChange={this.handleInputChange}
                />
                <span className="focus-border" />
                {this.state.emailConfirmError && (
                  <span className="text-danger">
                    {this.state.emailConfirmError}
                  </span>
                )}
              </fieldset>
              <button action="submit" className="btn btn-gymnerd">
                Update Email
              </button>
              {this.renderAlert()}
              {this.renderSuccess()}
            </form>
          </div>
          <div className="col-lg-3" />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="container">
        <section style={{ paddingTop: 40 }}>
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12">
              <p style={Styles.section}>Update Your Email</p>
            </div>
          </div>
        </section>
        {this.renderContent()}
        <div className="clearfix" />
      </div>
    );
  }
}

const Styles = {
  section: {
    fontFamily: 'black_ops_oneregular',
    fontSize: 30,
    fontWeight: 'bold'
  }
};

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    emailSuccess: state.auth.success,
    emailFail: state.auth.error
  };
}

export default connect(
  mapStateToProps,
  actions
)(UpdateEmail);
