import axios from 'axios';
import history from '../../config/history';

import {
  // TEST_ROOT_URL,
  LIVE_ROOT_URL
} from '../../config';

import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  FETCH_DATA,
  FETCH_DATA_FAIL,
  MESSAGE_FAIL,
  MESSAGE_SENT,
  LOADING,
  CLEAR_SUCCESS,
  RESET_ERROR,
  RESET_PASSWORD,
  RESET_SUCCESS,
  STRIPE_UPDATE_SUCCESS,
  STRIPE_UPDATE_FAIL,
  EMAIL_UPDATE_SUCCESS,
  EMAIL_UPDATE_FAIL,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAIL,
  ACCOUNT_CANCEL_SUCCESS,
  ACCOUNT_CANCEL_FAIL,
  PROMO_VALID,
  PROMO_FAIL
} from '../types';

export function sendMessage({ email, message }) {
  return function(dispatch) {
    dispatch({ type: LOADING });
    axios
      .post(`${LIVE_ROOT_URL}/contact`, { email, message })
      .then(response => {
        dispatch({ type: MESSAGE_SENT, payload: response.data.success });
      })
      .catch(() => {
        dispatch({ type: MESSAGE_FAIL, payload: 'Message Failed To Send' });
      });
  };
}

export function checkPromo(promo) {
  return function(dispatch) {
    axios
      .post(`${LIVE_ROOT_URL}/promo`, { promo })
      .then(response => {
        dispatch({ type: PROMO_VALID, payload: response.data.success });
      })
      .catch(error => {
        if (promo === '') {
          dispatch({ type: PROMO_FAIL, payload: '' });
        } else {
          dispatch({ type: PROMO_FAIL, payload: error.response.data.error });
        }
      });
  };
}

export function signinUser({ email, password }) {
  return function(dispatch) {
    // redux thunk in action
    // submit email/password to server - axios = promise
    dispatch({ type: LOADING });
    axios
      .post(`${LIVE_ROOT_URL}/signin`, { email, password })
      .then(response => {
        // if request is good ..
        // update state to indicate auth=ok (reducer)
        dispatch({ type: AUTH_USER });
        // and save jwt-token
        localStorage.setItem('token', response.data.token);
        // redirect to desired page - programatic navigation
        history.push('/profile');
      })
      .catch(() => {
        // else if request is bad - show error
        dispatch(authError('Bad Login Info'));
      });
  };
}

// ------------------------------------
// lets combine these two

// export function setStripeToken({ token }) {
//     console.log('setting stripe token');
//     return function(dispatch) {
//         dispatch({ type: SET_STRIPE_TOKEN, payload: token });
//     }
// }

// export function setEmail(email) {
//     console.log('Email is:', email);
//     return function(dispatch) {
//         dispatch({ type: SET_EMAIL, payload: email });
//     }
// }

// send: name, email, password, stripe token, promo
// get token and set to local storage

export function signupUser(name, email, password, stripeToken, promo) {
  return function(dispatch) {
    dispatch({ type: LOADING });
    axios
      .post(`${LIVE_ROOT_URL}/signup`, {
        name,
        email,
        password,
        stripeToken,
        promo
      })
      .then(response => {
        dispatch({ type: AUTH_USER, payload: email });
        localStorage.setItem('token', response.data.token);
        history.push('/profile');
      })
      .catch(error => {
        dispatch(authError(error.response.data.error));
      });
  };
}

// export function stripePay() {
//     return function(dispatch) {
//         dispatch({ type: LOADING });
//         axios.post(`${LIVE_ROOT_URL}/stripepay`, {
//             authorization: localStorage.getItem('token'),
//             email: localStorage.getItem('email'),
//             stripeToken: localStorage.getItem('st'),
//             promo: localStorage.getItem('promo')
//         }).then(response => {
//             localStorage.removeItem('st');
//             dispatch({ type: STRIPE_SUCCESS, payload: response.data.success });
//             history.push('/profile');
//         }).catch(error => {
//             dispatch({ type: STRIPE_FAIL, payload: error.response.data.error });
//         });
//     }
// }

// ---------------------------------------------

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error
  };
}

export function signOutUser() {
  return function(dispatch) {
    localStorage.removeItem('token');
    dispatch({ type: UNAUTH_USER });
  };
}

export function resetError() {
  return { type: RESET_ERROR };
}

export function clearSuccess() {
  return { type: CLEAR_SUCCESS };
}

export function fetchUserInformation() {
  return function(dispatch) {
    dispatch({ type: LOADING });
    axios
      .get(`${LIVE_ROOT_URL}/user`, {
        headers: { authorization: localStorage.getItem('token') }
      })
      .then(response => {
        dispatch({ type: FETCH_DATA, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FETCH_DATA_FAIL, payload: error.response.data.error });
      });
  };
}

export function stripeUpdate({ email, id }) {
  return function(dispatch) {
    dispatch({ type: LOADING });
    axios
      .post(`${LIVE_ROOT_URL}/stripe`, {
        authorization: localStorage.getItem('token'),
        email: email,
        stripeToken: id
      })
      .then(response => {
        localStorage.removeItem('st');
        dispatch({
          type: STRIPE_UPDATE_SUCCESS,
          payload: response.data.success
        });
        history.push('/profile');
      })
      .catch(error => {
        dispatch({
          type: STRIPE_UPDATE_FAIL,
          payload: error.response.data.error
        });
      });
  };
}

export function cancel() {
  return function(dispatch) {
    dispatch({ type: LOADING });
    axios
      .post(`${LIVE_ROOT_URL}/cancel`, {
        authorization: localStorage.getItem('token')
      })
      .then(response => {
        dispatch({
          type: ACCOUNT_CANCEL_SUCCESS,
          payload: response.data.success
        });
        history.push('/profile');
      })
      .catch(error => {
        dispatch({
          type: ACCOUNT_CANCEL_FAIL,
          payload: error.response.data.error
        });
      });
  };
}

export function forgotPassword({ email }) {
  return function(dispatch) {
    dispatch({ type: LOADING });
    axios
      .post(`${LIVE_ROOT_URL}/forgotpassword`, {
        email: email
      })
      .then(response => {
        dispatch({ type: RESET_PASSWORD, payload: response.data.success });
      })
      .catch(err => {
        dispatch(authError(err.response.data.error));
      });
  };
}

export function resetPassword({ token, password }) {
  return function(dispatch) {
    dispatch({ type: LOADING });
    axios
      .post(`${LIVE_ROOT_URL}/reset`, {
        password,
        token
      })
      .then(response => {
        dispatch({ type: RESET_SUCCESS, payload: response.data.success });
      })
      .catch(error => {
        dispatch(authError(error.response.data.error));
      });
  };
}

export function changeEmail({ email }) {
  return function(dispatch) {
    dispatch({ type: LOADING });
    axios
      .post(`${LIVE_ROOT_URL}/email`, {
        authorization: localStorage.getItem('token'),
        email: email
      })
      .then(response => {
        dispatch({
          type: EMAIL_UPDATE_SUCCESS,
          payload: response.data.success
        });
      })
      .catch(error => {
        dispatch({
          type: EMAIL_UPDATE_FAIL,
          payload: error.response.data.error
        });
      });
  };
}

export function changePassword({ password }) {
  return function(dispatch) {
    dispatch({ type: LOADING });
    axios
      .post(`${LIVE_ROOT_URL}/password`, {
        authorization: localStorage.getItem('token'),
        password: password
      })
      .then(response => {
        dispatch({
          type: PASSWORD_UPDATE_SUCCESS,
          payload: response.data.success
        });
      })
      .catch(error => {
        dispatch({
          type: PASSWORD_UPDATE_FAIL,
          payload: error.response.data.error
        });
      });
  };
}
